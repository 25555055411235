import {
  Assignment, CommuteOutlined, Redeem, ScaleOutlined, WorkOutlineTwoTone,
} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HandymanIcon from '@mui/icons-material/Handyman';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box } from '@mui/material';
import { useRoleContext } from 'hooks';
import {
  capitalizeFirst,
  formatText,
  getCommdityNameForCondensedShipmentShipment,
  getCurrencyName, getEquipmentTypesDisplay as getEquipmentTypesAsSingleString, lbToKg,
} from 'utils';
import { getServiceTypeDisplayLabel } from '../../../../constants';
import { ShipmentDetail } from '../ShipmentDetail';

interface Props {
  shipment: CondensedShipment | undefined
}
export function ShipmentDetails(props: Props) {
  const {
    shipment,
  } = props;
  const { role } = useRoleContext();

  const shipmentInfo = shipment?.info;
  const equipmentTypes = shipment?.equipments;

  const getEquipmentTypesDisplay = () => {
    if (!equipmentTypes) {
      return '';
    }
    const equipmentTypeNames = equipmentTypes.map((eq) => eq.name);
    return getEquipmentTypesAsSingleString(equipmentTypeNames);
  };
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
      {role != null && role.type === 'CARRIER' && shipment?.book_now_info.is_book_now && (
      <ShipmentDetail
        icon={<AttachMoneyIcon fontSize="small" />}
        title="Target Price"
        text={shipment?.book_now_info?.book_now_price
           && shipment?.book_now_info?.book_now_price !== 0
          && shipment?.book_now_info?.book_now_currency ? `$${shipment?.book_now_info?.book_now_price}
          ${getCurrencyName(shipment?.book_now_info.book_now_currency)}` : '-'}
      />
      )}
      <ShipmentDetail
        icon={<Redeem />}
        title="Commodity"
        text={shipment?.info ? capitalizeFirst(
          formatText(getCommdityNameForCondensedShipmentShipment(shipment.info)),
        ) : '-'}
      />
      <ShipmentDetail
        icon={<ScaleOutlined fontSize="small" />}
        title="Weight"
        text={shipmentInfo?.weight_lb != null
          ? `${parseFloat(shipmentInfo.weight_lb.toString()).toFixed()} lb (${lbToKg(parseFloat(shipmentInfo.weight_lb.toString())).toFixed(1)}kg)`
          : '-'}
      />
      <ShipmentDetail
        icon={<WorkOutlineTwoTone fontSize="small" />}
        title="Service"
        text={getServiceTypeDisplayLabel(shipmentInfo?.service ?? '-')}
      />
      <ShipmentDetail
        icon={<CommuteOutlined fontSize="small" />}
        title="Equipment Type(s)"
        text={equipmentTypes && equipmentTypes.length > 0 ? getEquipmentTypesDisplay() : '-'}
      />
      <ShipmentDetail
        icon={<Assignment fontSize="small" />}
        title="Product Dimensions"
        multiline
        text={shipment?.info?.additional_details ? shipment.info.additional_details : '-'}
      />
      <ShipmentDetail
        icon={<HandymanIcon fontSize="small" />}
        title="Special Requirements"
        multiline
        text={shipment?.info?.special_requirements ? shipment.info.special_requirements : '-'}
      />
      {role != null && role.type === 'SHIPPER' && shipment?.book_now_info.is_book_now && (
      <ShipmentDetail
        icon={<AttachMoneyIcon fontSize="small" />}
        title="Book Now Price"
        text={shipment?.book_now_info?.book_now_price
           && shipment?.book_now_info?.book_now_price !== 0
          && shipment?.book_now_info?.book_now_currency ? `$${shipment?.book_now_info?.book_now_price}
          ${getCurrencyName(shipment?.book_now_info.book_now_currency)}` : '-'}
      />
      )}
      {
        shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT' && shipment?.project && (
          <ShipmentDetail
            icon={<LocalShippingIcon />}
            title="Available Shipments"
            text={shipment?.project?.loads_available}
          />
        )
      }
      {
        shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT' && shipment?.project && (
          <ShipmentDetail
            icon={<CheckBoxIcon />}
            title="Shipments Booked"
            text={shipment?.project?.loads_booked}
          />
        )
      }

    </Box>
  );
}
