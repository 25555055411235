import { Stack, Typography } from '@mui/material';
import { CarrierCompanyNameAndReputationLabel } from 'components/CarrierCompanyNameAndReputationLabel';
import { CarrierInsuranceDocs } from 'components/CarrierInsuranceDocs';
import { AddCarrierToNetworkButton } from 'components/Shipper/components/AddCarrierToNetworkButton';
import { useCompanyProfile } from 'hooks';

interface Props {
  quote: ShipperCondensedQuote
}

export function CarrierCompanyQuoteDetail(props: Props) {
  const { quote } = props;
  const { companyProfile } = useCompanyProfile(quote?.created_by_company.id);
  return (
    <Stack
      id="carrier-profile-preview"
      direction="column"
      sx={{
        borderColor: '#cecdcd',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 2,
        p: '.3rem',
        pr: '2rem',
        width: '70%',
      }}
      spacing={1}
    >
      <Typography>
        <b>Carrier: </b>
        {' '}
        {quote?.created_by_company?.name || ''}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>
          <b>{'Insurance documents & more: '}</b>
        </Typography>
        { companyProfile && (<CarrierInsuranceDocs companyProfile={companyProfile} chipSize="small" />)}
      </Stack>
      <Stack id="carrier-profile" direction="row" flexWrap="wrap" spacing={1} alignItems="center" sx={{ width: '100%' }}>
        <Typography><b>{'Profile: '}</b></Typography>
        <CarrierCompanyNameAndReputationLabel
          companyId={quote?.created_by_company?.id}
          companyBusinessType={quote?.created_by_company?.business_type}
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start">
        {!quote.is_partner_quote && (
        <AddCarrierToNetworkButton carrierId={quote.carrier_id} checkForRequestAlreadySent />
        )}
      </Stack>
    </Stack>
  );
}
