import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Divider, Modal, Paper, Stack, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography,
} from '@mui/material';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useFetchingComponentState, useRoleContext, useShipperShipmentPageContext } from 'hooks';
import { MODAL_STYLE } from 'layouts/constants';
import { useEffect, useState } from 'react';
import { ShipmentProgressDetailTableRow } from './ShipmentProgressDetailTableRow';
import { getShipmentDetailTableTitle } from './utils';

interface Props {
  shipment: ShipperCondensedShipment | undefined
  progress: ShipmentProgress | null
  open: boolean;
  onClose: VoidFunction;
}

export function ShipmentProgressDetail(props: Props) {
  const {
    shipment,
    progress,
    open,
    onClose,
  } = props;
  const { onRefresh } = useShipperShipmentPageContext();
  const { role } = useRoleContext();
  const [followShipperRequests,
    setFollowShipperRequests] = useState<FollowShipperRequestAndResponse[] | null>(null);
  const {
    fetch: fetchSentRequests,
  } = useFetchingComponentState<FollowShipperRequestAndResponse[]>({
    errorMessage: '',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/follow_requests/sent` : '/',
    },
  });

  useEffect(() => {
    if (role != null && followShipperRequests == null) {
      fetchSentRequests((res) => setFollowShipperRequests(res));
    }
  }, [role, followShipperRequests, setFollowShipperRequests, fetchSentRequests]);

  if (!open || !shipment || !progress) {
    return <div />;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={MODAL_STYLE}>
        <Stack justifyContent="flex-end" direction="row">
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onClose}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h5">{getShipmentDetailTableTitle(shipment, progress)}</Typography>
          <RefreshPageButton
            id="shipper-shipment-overview-refresh"
            iconButton
            hardReload={false}
            onRefresh={onRefresh || (() => {})}
          />
        </Stack>
        <Divider sx={{ my: '1rem' }} />
        <TableContainer id="table-container" component={Paper} sx={{ overflowY: 'auto', maxHeight: '500px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Contact</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Viewed</TableCell>
                <TableCell>Quoted</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { progress.match_progress.length > 0 || progress.network_progress.length > 0
                ? progress.network_progress.concat(
                  progress.match_progress,
                ).map((carrierProgress) => (
                  <ShipmentProgressDetailTableRow
                    key={carrierProgress.carrier.user.id}
                    carrierProgress={carrierProgress}
                    followShipperRequestsSent={followShipperRequests}
                  />
                )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}
