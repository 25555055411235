import { toFullReadableAddress } from 'utils';

export function getAddressReadable(point: Partial<RoutePoint>) {
  const { point_location: location } = point;
  if (location === undefined) {
    return '-';
  }
  if (location?.addressReadable
    && location?.address
    && location.address.length > 0) {
    return location.addressReadable;
  }
  return toFullReadableAddress(point.point_location);
}
