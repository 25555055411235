import {
  Box, Divider, Modal, Typography,
} from '@mui/material';
import { MODAL_STYLE } from 'layouts/constants';

interface Props {
  form: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
}

export function RoutesFormModal(props: Props) {
  const { open, onClose, form } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={MODAL_STYLE}>
        <Typography variant="h6" component="h2">Create Route Stop</Typography>
        <Divider sx={{ my: '1rem' }} />
        {form}
      </Box>
    </Modal>
  );
}
