import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Divider, Stack,
  Typography,
} from '@mui/material';
import { QuoteStatusBadge } from 'components/QuoteStatusBadge';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentPriceText } from 'components/Texts/ShipmentPriceText';
import { ShipmentShipperReferenceText } from 'components/Texts/ShipmentShipperReferenceText';
import { useNavigate } from 'react-router-dom';
import { ISOStringToShorthandDate, toCityProvinceCountryAndZipReadablePointLocation } from 'utils';
import { QuotePreviewShipmentIDText } from './components/QuotePreviewShipmentIDText';

interface Props {
  quote: CondensedQuote;
}

export function QuotePreview(props: Props) {
  const { quote } = props;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
          title: 'View Quote',
        },
      }}
      onClick={() => navigate(`/shipments/${quote.shipment}?focus=quotes`)}
    >
      <Stack
        direction="row"
        p="1rem"
        spacing={0.5}
      >
        <Stack id="left-side" direction="column" sx={{ width: '30%' }} spacing={0.5}>
          <Box>
            <QuoteStatusBadge status={quote?.internal_state ?? 'unknown'} size="medium" isHidden={quote.hidden_at != null} />
          </Box>
          <QuotePreviewShipmentIDText quote={quote} />
          <ShipmentEquipmentTypeText data={[{ name: quote?.equipment_type.toLowerCase().split('_').join(' ') } as Equipment]} />
          <ShipmentPriceText
            price={quote.price}
            currency={quote?.currency}
          />
        </Stack>
        <Stack
          id="right-half-quote-preview"
          direction="column"
          sx={{ width: '70%' }}
        >
          <Stack
            id="left-to-right-route-points"
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '90%' }}
          >
            <Stack id="origin" direction="column" alignItems="center">
              <ShipmentLocationText
                data={toCityProvinceCountryAndZipReadablePointLocation(quote.origin_location)}
                icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
              <Typography variant="body1">{ISOStringToShorthandDate(quote.pickup_start)}</Typography>
            </Stack>
            <Box flex={1} mx="1rem">
              <Divider />
            </Box>
            <Stack id="destination" direction="column" alignItems="center">
              <ShipmentLocationText
                data={toCityProvinceCountryAndZipReadablePointLocation(quote.destination_location)}
                icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
              <Typography variant="body1">{ISOStringToShorthandDate(quote.pickup_end)}</Typography>
            </Stack>
          </Stack>
          <Box>
            <Stack
              direction="column"
              justifyContent="center"
              flexWrap="wrap"
              alignItems="center"
            >
              {quote && quote.shipment_custom_name != null && (
              <ShipmentShipperReferenceText data={quote.shipment_custom_name} />
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
