import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Button, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import {
  useDisclosure, useRoleContext, useShipment,
} from 'hooks';
import { getShipmentIdFromShipmentBookedChatName, toCityProvinceCountryAndZipReadablePointLocation } from 'utils';
import { ShipmentBookedBy } from '../ShipmentBookedBy';
import { ShipmentBookedPrice } from '../ShipmentBookedPrice';
import { ChatAddCarrierToNetworkButton } from './ChatAddCarrierToNetworkButton';
import { FullRouteDialog } from './FullRouteDialog';
import { MuteChatButton } from './MuteChatButton';

interface Props {
  currentChannel: string;
}
export function ChatConversationHeader(props: Props) {
  const { currentChannel } = props;
  const shipmentId = getShipmentIdFromShipmentBookedChatName(currentChannel);
  const { shipment, loading } = useShipment({ shipmentId: parseInt(shipmentId, 10) });
  const { role } = useRoleContext();
  const {
    isOpen: openRouteAlert,
    onClose: onCloseRouteAlert,
    onOpen: onOpenRouteAlert,
  } = useDisclosure(false);

  let component = <div />;
  if (loading || shipment == null) {
    component = (
      <Stack alignItems="center" direction="column">
        <Typography variant="h4">{`Shipment ${shipmentId}`}</Typography>
        <CircularProgress />
      </Stack>
    );
  } else if (shipment && shipment.info.id.toString() !== shipmentId) {
    // component returns empty header if the header shpiment id and the shipment passed do not match
    return (<div />);
  } else if (shipment) {
    component = (
      <Box>
        <Stack direction="column" alignItems="center">
          <Box sx={{ cursor: 'pointer' }} onClick={() => { window.open(`/shipments/${shipmentId}`, '_blank'); }}>
            <Typography variant="h4" color="blue">
              <u>
                {`Shipment ${shipment.info.id}`}
                {shipment.custom_name != null ? ` - ${shipment.custom_name}` : ''}
              </u>
            </Typography>
          </Box>
          <Stack sx={{ mt: '1rem' }} direction="row" spacing={2} flexWrap="wrap">
            <Stack direction="column">
              <Stack direction="row" spacing={1}>
                <ShipmentLocationText
                  data={toCityProvinceCountryAndZipReadablePointLocation(
                    shipment.first_point.point_location,
                  )}
                  icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
                />
                <ArrowForwardIcon />
                <ShipmentLocationText
                  data={toCityProvinceCountryAndZipReadablePointLocation(
                    shipment?.last_point.point_location,
                  )}
                  icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
                />
              </Stack>
              <Button onClick={onOpenRouteAlert}>View Full Route</Button>
            </Stack>
            <FullRouteDialog
              shipment={shipment}
              isOpen={openRouteAlert}
              onClose={onCloseRouteAlert}
            />
            <Divider orientation="vertical" />
            <ShipmentBookedPrice shipment={shipment} />
            <Divider orientation="vertical" />
            <ShipmentBookedBy shipment={shipment} />

          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
            {role != null && role.type === 'SHIPPER' && (
            <ChatAddCarrierToNetworkButton shipment={shipment} buttonVariant="contained" />
            )}
            <MuteChatButton
              currentChannel={currentChannel}
            />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing={1} p=".7rem" justifyContent="center">
      {component}
    </Stack>
  );
}
