import { TextField } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function AdditionalDetailsTextField(props: Props) {
  const { value, onChange } = props;
  return (
    <TextField
      label={value.length > 0 ? '' : 'Product Dimensions'}
      helperText="Product dimensions, number of items, stackable/non-stackable, etc."
      value={value}
      onChange={(event) => onChange(event.target.value)}
      sx={{ width: '50%' }}
      multiline
      maxRows={20}
      minRows={1}
    />
  );
}
