import { Chip, Tooltip } from '@mui/material';

interface Props {
  insurance: CarrierInsurance;
  chipSize?: 'small' | 'medium' ;
}

export function CarrierInsuranceStatusChip(props: Props) {
  const { insurance, chipSize } = props;

  if (insurance.marked_expired) {
    return <Chip label="Expired" color="error" size={chipSize} />;
  }
  return (
    <Tooltip title="Insurance documentation is up to date.">
      <Chip label="Current" color="success" size={chipSize} />
    </Tooltip>
  );
}

CarrierInsuranceStatusChip.defaultProps = {
  chipSize: undefined,
};
