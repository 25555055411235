import { PROVINCE_STATE_VALUE_TO_LABEL_MAP } from '../../constants';
import { RegionOption } from './types';

export function getRegionByCode(code: string): string {
  return PROVINCE_STATE_VALUE_TO_LABEL_MAP[code] || '';
}

export function getDefaultOption(defaultValue: string): RegionOption | null {
  if (defaultValue === '') { return null; }
  const option: RegionOption = {
    label: getRegionByCode(defaultValue),
    value: defaultValue,
  };
  return option;
}
