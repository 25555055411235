import { filterDirectInviteCarrierACLs } from 'utils';

function getTemplateRoute(route: Route): ShipmentTemplateRoutePoint[] {
  return route.route_points.map((p: RoutePoint) => (
    {
      point: {
        isBeingEdited: true,
        stop_type: p.stop_type,
        stop_operation: p.stop_operation,
        start_time: p.start_time,
        end_time: p.end_time,
        point_number: p.point_number,
        point_location: {
          city: p.point_location.city,
          country: p.point_location.country,
          province: p.point_location.province,
          postal: p.point_location.postal,
          longitude: p.point_location.longitude,
          latitude: p.point_location.latitude,
          address: p.point_location.address,
          created_at: p.point_location.created_at,
        },
      },
      point_location: {
        city: p.point_location.city,
        country: p.point_location.country,
        province: p.point_location.province,
        postal: p.point_location.postal,
        longitude: p.point_location.longitude,
        latitude: p.point_location.latitude,
        address: p.point_location.address,
        created_at: p.point_location.created_at,
      },
    }
  ));
}

export function geCreateTemplateBodyFromCondensedShipment(
  shipment: ShipperCondensedShipment,
  templateName: string,
  route: Route | null,
): ShipmentTemplateContent {
  /*
  Creates a ShipmentTemplateContent (the body required to create a shipment) from an existing
  ShipperCondensedShipment.
  */
  return {
    shipment: {
      footage: shipment?.info?.footage ?? null,
      service: shipment?.info?.service ?? null,
      currency: null,
      type: shipment?.info?.type && (shipment?.info?.type === 'SINGLE' || shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT') ? shipment?.info?.type : 'SINGLE',
      commodity: shipment?.info?.commodity?.name ?? null,
      weight_lb: shipment?.info?.weight_lb ?? null,
      preferred_price: shipment?.info?.preferred_price ?? null,
      additional_details: shipment?.info?.additional_details ?? null,
      commodity_freetext: shipment?.info?.commodity_freetext ?? null,
      special_requirements: shipment?.info?.special_requirements ?? null,
    },
    template_name: templateName,
    equipment_types: shipment?.equipments.map((equipment: Equipment) => equipment.type) ?? [],
    notification_settings_emails: filterDirectInviteCarrierACLs(
      shipment.carrier_acl,
    ).map((acl) => acl.role.user.email),
    notification_settings_type: shipment.network_notification_type ?? 'ALL_CARRIER_NETWORK',
    route: route != null ? getTemplateRoute(route) : [],
    sharee_role_ids: shipment?.sharees ? shipment.sharees.map(
      (sharee: ShipmentSharee) => sharee.sharee.id,
    ) : [],
    project: shipment?.info?.type && shipment.info.type === 'MULTI_SHIPMENT_PROJECT' ? {
      loads_available: shipment?.project?.loads_available,
    } : null,
    notification_settings_partner_groups: shipment.network_groups.map(
      (group) => group.id,
    ) ?? [],
  };
}
