import {
  Box,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { RouteTab } from 'components/ShipmentOverviewTab/components/RouteTab/RouteTab';
import { useShipmentRoute } from 'hooks';
import { MODAL_STYLE } from 'layouts/constants';

interface Props {
  shipment: CondensedShipment | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export function FullRouteDialog(props: Props) {
  const { shipment, isOpen, onClose } = props;
  const {
    route,
    loading,
    error,
  } = useShipmentRoute(shipment?.info.route);
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={MODAL_STYLE}>
        <Stack direction="column">
          <Typography variant="h4">{`Shipment ${shipment?.info.id} Route`}</Typography>
          <RouteTab
            route={route}
            loading={loading}
            error={error}
          />
        </Stack>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
