import {
  Typography,
} from '@mui/material';
import { useRoleContext } from '../../../../hooks';
import { toFullReadableAddress } from '../../../../utils';
import { CarrierRoutePointLocationDisplay } from './components/CarrierRoutePointLocationDisplay';

interface Props {
  routePoint: RoutePoint;
}

export function RoutePointLocationDisplay(props: Props) {
  const { routePoint } = props;
  const { role } = useRoleContext();
  if (role && role.type === 'CARRIER') {
    return <CarrierRoutePointLocationDisplay routePoint={routePoint} />;
  }
  return (
    <Typography variant="h6">{toFullReadableAddress(routePoint.point_location)}</Typography>
  );
}
