import { Stack, Typography } from '@mui/material';
import { CarrierInsuranceStatusChip } from 'components/CarrierInsuranceStatusChip';
import { NewTabLink } from 'components/NewTabLink';

interface Props {
  companyProfile: CompanyProfile
  chipSize?: 'small' | 'medium'
}

export function CarrierInsuranceDocs(props: Props) {
  const { companyProfile, chipSize } = props;

  if (companyProfile.carrier_insurance) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <NewTabLink href={companyProfile.carrier_insurance.url}>
          <Typography variant="body1">View Documents</Typography>
        </NewTabLink>
        <CarrierInsuranceStatusChip
          insurance={companyProfile.carrier_insurance}
          chipSize={chipSize}
        />
      </Stack>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">This carrier has not added their documents yet.</Typography>
  );
}

CarrierInsuranceDocs.defaultProps = {
  chipSize: undefined,
};
