import {
  Box, Divider, Stack, Typography,
} from '@mui/material';
import { useState } from 'react';
import { shipmentIsBooked, shipperFilterQuotes, shipperSortQuotes } from 'utils';
import { ShipmentQuote } from '../../../ShipmentQuote';
import { QuoteFilterMenu } from './QuoteFilterMenu';
import { QuoteSortMenu } from './QuoteSortMenu';

interface Props {
  shipment: ShipperCondensedShipment;
}

export function ShipmentQuoteList(props: Props) {
  const {
    shipment,
  } = props;

  // if more than one partner quote, make partner the default ;)
  const [currentSort, setCurrentSort] = useState<QuoteSortType>('ALL');

  // if shipment is booked, default to accepted filter. Else default to active quotes filter
  const [currentFilter,
    setCurrentFilter] = useState<QuoteFilterType>(shipmentIsBooked(shipment) ? 'ACCEPTED' : 'ACTIVE');

  // pre-filter quotes to remove quotes by blocked carriers
  const preFilteredQuotes = shipment.condensed_quotes.filter(
    (quote: ShipperCondensedQuote) => !quote.carrier_is_blocked,
  );
  const filteredQuotes = shipperFilterQuotes(preFilteredQuotes, currentFilter);
  const orderedQuotes = shipperSortQuotes(filteredQuotes, currentSort);
  const orderedAndFilteredMinusHidden = orderedQuotes.filter(
    (
      quote: CondensedQuote,
    ) => quote.hidden_at == null,
  );

  let quotesListComponent;
  if (shipment.condensed_quotes.length === 0) {
    quotesListComponent = (
      <Typography variant="body1" textAlign="center">
        You have not received any quotes on this shipment.
      </Typography>
    );
  } else {
    quotesListComponent = (
      <Stack direction="column" mt=".5rem">
        <Stack direction="column" mb=".5rem" alignItems="center">
          <QuoteFilterMenu
            value={currentFilter}
            onChange={(value: QuoteFilterType) => setCurrentFilter(value)}
            shipmentBooked={shipmentIsBooked(shipment)}
            showCancelled={shipment.condensed_quotes.some((quote: ShipperCondensedQuote) => quote.internal_state === 'CANCELLED')}
            showCountered={shipment.condensed_quotes.some((quote: ShipperCondensedQuote) => quote.internal_state === 'COUNTERED')}
          />
          <QuoteSortMenu
            value={currentSort}
            onChange={(value: QuoteSortType) => setCurrentSort(value)}
          />
          <Typography color="text.secondary" variant="body1">{`${orderedAndFilteredMinusHidden.length} quote${orderedAndFilteredMinusHidden.length === 1 ? '' : 's'} matched`}</Typography>
        </Stack>
        <Divider />
        {
          orderedAndFilteredMinusHidden.length === 0 && (
            <Typography variant="body1" textAlign="center">
              Filter and sort criteria did not match any quote. Please try a different search.
            </Typography>
          )
        }
        {orderedAndFilteredMinusHidden.map((quote) => (
          <div key={`${quote.quote_action}`}>
            <ShipmentQuote quote={quote} />
            <Box pt={1}>
              <Divider />
            </Box>
          </div>
        ))}
      </Stack>
    );
  }

  return quotesListComponent;
}
