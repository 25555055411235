import {
  Box, Button, CircularProgress, Modal, Stack, TextField, Typography,
} from '@mui/material';
import { MODAL_STYLE } from 'layouts/constants';

interface Props {
  modalOpen: boolean;
  error: string | null;
  setTemplateName: (value: string) => void;
  templateName: string;
  onSubmit: VoidFunction;
  loading: boolean;
  success: boolean;
  onModalClose: VoidFunction;
}

export function CreateShipmentTemplateModal(props: Props) {
  const {
    modalOpen,
    error,
    setTemplateName,
    templateName,
    onSubmit,
    loading,
    success,
    onModalClose,
  } = props;
  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <Box sx={MODAL_STYLE}>
        <Typography variant="h3" sx={{ mb: '2rem' }}>Name your shipment template</Typography>
        <Stack direction="column" spacing={2} alignItems="center">
          <TextField
            id="name-shipment-template"
            required
            label="Name"
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
            sx={{ width: '100%' }}
            value={templateName}
            placeholder="My weekly shipment"
          />
          {error != null && <Typography color="red" align="center">{error}</Typography>}
          {success && <Typography color="green" align="center">Shipment template created.</Typography>}
          <Stack direction="row" spacing={1}>
            {loading ? <CircularProgress /> : (
              <Button
                size="large"
                variant="contained"
                onClick={onSubmit}
                disabled={templateName.length < 1}
              >
                Save
              </Button>
            )}
            <Button variant="outlined" size="large" onClick={onModalClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
