import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';

export function useShipmentRoute(routeId: number | undefined) {
  const [routeResponse, setRouteResponse] = useState<GetRouteResponse | null>(null);
  const {
    fetch, loading, error, success, refetch,
  } = useFetchingComponentState<GetRouteResponse>({
    errorMessage: 'Failed to fetch route',
    fetchConfig: {
      url: routeId !== undefined ? `/routes/${routeId}` : '/',
    },
  });

  useEffect(() => {
    if (!success && error === null) {
      fetch((res) => {
        setRouteResponse(res);
      });
    }
  }, [fetch, loading, error, success]);

  let route: Route | null = null;
  if (routeResponse !== null) {
    route = {
      id: routeResponse?.route?.id,
      type: routeResponse?.route?.type,
      route_points: routeResponse?.points || [],
      created_at: routeResponse?.route?.created_at,
      navigation: routeResponse?.navigation || null,
    };
  }

  return {
    route,
    error,
    loading,
    refetchRoute: () => refetch(),
  };
}
