export function sortQuotes(quotes: CondensedQuote[]): CondensedQuote[] {
  return quotes.sort((a, b) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    if (aDate < bDate) {
      return 1;
    }
    if (aDate > bDate) {
      return -1;
    }
    return 0;
  });
}

export function quoteCountText(quoteCount: number): string {
  if (quoteCount === 1) {
    return `${quoteCount} Quote`;
  }
  return `${quoteCount} Quotes`;
}

export function roleOwnsLatestQuote(quote: CondensedQuote, role: Role) {
  if (quote?.company != null && quote?.company !== undefined && role?.company?.id != null) {
    return quote.company.id === role.company.id;
  }
  return false;
}

export const getQuoteActionNameAndCompany = (quote: CondensedQuote) => `${quote?.action_user_author?.first_name ?? '-'} ${quote?.action_user_author?.last_name ?? '-'} from ${quote?.company?.name ?? '-'}`;

export const getSenderVerb = (quote: CondensedQuote) => {
  if (quote.internal_state === 'ACTIVE' || quote.internal_state === 'EXPIRED'
  || quote.internal_state === 'SHIPMENT_CANCELLED'
  || quote.internal_state === 'SHIPMENT_EXPIRED'
  || quote.internal_state === 'SHIPMENT_LOST') {
    return 'Quoted';
  }
  if (quote.internal_state === 'COUNTERED') {
    return 'Countered';
  }
  if (quote.internal_state === 'CANCELLED') {
    return 'Cancelled';
  }

  if (quote.internal_state === 'ACCEPTED') {
    return 'Booked';
  }

  return '';
};

export const getLatestActionAuthor = (quote: CondensedQuote, role: Role | null) => {
  if (!role) {
    return '';
  }
  if (roleOwnsLatestQuote(quote, role) && role.type === 'SHIPPER') {
    return `${getSenderVerb(quote)} by you to ${quote?.created_by?.first_name ?? '-'} ${quote?.created_by?.last_name ?? '-'} from ${quote?.created_by_company?.name ?? '-'}`;
  }

  if (roleOwnsLatestQuote(quote, role) && role.type === 'CARRIER') {
    return `${getSenderVerb(quote)} by you`;
  }

  if (role.type === 'SHIPPER') {
    return `${getSenderVerb(quote)} by ${getQuoteActionNameAndCompany(quote)}`;
  } if (role.type === 'CARRIER') {
    // carriers do not get to see full name and company info,
    // only first name and initial of last name
    return `${getSenderVerb(quote)} by ${quote?.action_user_author?.first_name ?? '-'} ${quote?.action_user_author?.last_name && quote?.action_user_author?.last_name.length > 0 ? `${quote?.action_user_author?.last_name.charAt(0)}.` : ''}`;
  }
  return '';
};

export const getQuoteSenderLabel = (quote: CondensedQuote, role: Role | null) => {
  if (role && (quote.internal_state === 'ACTIVE' || quote.internal_state === 'COUNTERED'
  || quote.internal_state === 'EXPIRED' || quote.internal_state === 'CANCELLED' || quote.internal_state === 'SHIPMENT_LOST'
  || quote.internal_state === 'SHIPMENT_CANCELLED' || quote.internal_state === 'SHIPMENT_EXPIRED'
  || quote.internal_state === 'ACCEPTED')) {
    return getLatestActionAuthor(quote, role);
  }

  return '';
};

export const getNoteLabel = (quote: CondensedQuote, role: Role | null) => {
  if (role == null) {
    return '';
  }
  if (quote?.internal_state === 'ACTIVE') {
    return 'Note:';
  }
  if (quote?.internal_state === 'COUNTERED') {
    if (role && role.type === 'SHIPPER' && roleOwnsLatestQuote(quote, role)) {
      return 'Note:';
    }

    if (role && role.type === 'SHIPPER' && !roleOwnsLatestQuote(quote, role)) {
      return 'Note from carrier:';
    }

    if (role && role.type === 'CARRIER' && roleOwnsLatestQuote(quote, role)) {
      return 'Note:';
    }
    if (role && role.type === 'CARRIER' && !roleOwnsLatestQuote(quote, role)) {
      return 'Note from shipper:';
    }
  }
  return 'Note:';
};

export const getQuoteTitle = (quote: CondensedQuote) => {
  if (quote.internal_state === 'ACTIVE' || quote.internal_state === 'COUNTERED'
  || quote.internal_state === 'CANCELLED' || quote.internal_state === 'EXPIRED'
  || quote.internal_state === 'SHIPMENT_CANCELLED'
  || quote.internal_state === 'SHIPMENT_EXPIRED'
  || quote.internal_state === 'ACCEPTED' || quote.internal_state === 'SHIPMENT_LOST' || quote.internal_state === 'DECLINED'
  || quote.internal_state === 'SHIPMENT_LOST'
  ) {
    return `$${quote?.price ?? '-'} ${quote?.currency ?? '-'}`;
  }
  return '';
};

export const getBookedQuoteFromQuotes = (quotes: CondensedQuote[]) => quotes.find((q: CondensedQuote) => q.internal_state === 'ACCEPTED');

export const getBookedQuoteForShipperCondensedShipment = (shipment: ShipperCondensedShipment) => {
  const booked = getBookedQuoteFromQuotes(shipment.condensed_quotes);
  return booked;
};

export const shipperFilterQuotes = (quotes: ShipperCondensedQuote[], filter: QuoteFilterType) => {
  if (filter === 'ALL') {
    return quotes;
  }
  if (filter === 'PARTNER') {
    return quotes.filter((quote) => quote.is_partner_quote);
  }
  if (filter === 'ACTIVE') {
    return quotes.filter((quote) => quote.internal_state === 'ACTIVE' || quote.internal_state === 'COUNTERED');
  }
  if (filter === 'CANCELLED'
   || filter === 'COUNTERED' || filter === 'ACCEPTED') {
    return quotes.filter((quote) => quote.internal_state === filter);
  }
  if (filter === 'LOST') {
    return quotes.filter((quote) => quote.internal_state !== 'ACCEPTED' && quote.internal_state !== 'CANCELLED');
  }

  if (filter === 'BY_TRUCKING_COMPANY') {
    return quotes.filter((quote) => quote.created_by_company.business_type === 'TRUCKING_COMPANY');
  }

  if (filter === 'BY_BROKER') {
    return quotes.filter((quote) => quote.created_by_company.business_type === 'BROKER');
  }

  return quotes;
};
