import {
  useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, useInterval, usePasswordFieldToggle } from '../../../../hooks';
import { validateEmail } from '../../../../utils';
import { EmailAndPasswordFormErrorState } from '../../types';
import { validatePassword } from '../utils';
import { SignUp } from './SignUp';
import { SignUpData } from './types';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const RECAPTCHA_ACTION = 'SIGNUP';

const validationFns: { [key: string]: (val: string) => string | null } = {
  email: validateEmail,
  password: validatePassword,
};

export function SignUpContainer() {
  const navigate = useNavigate();
  const { signup } = useAuthContext();
  const [signupError, setSignupError] = useState<null | string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [form, setForm] = useState<SignUpData>({
    email: '',
    confirmEmail: '',
    password: '',
    firstName: '',
    lastName: '',
    role: undefined,
    recaptchaToken,
  });

  const [formError, setFormError] = useState<EmailAndPasswordFormErrorState>({
    email: null,
    password: null,
  });

  const executeReCaptcha = useCallback(() => {
    // @ts-ignore
    if (window?.grecaptcha) {
      // @ts-ignore
      window.grecaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: RECAPTCHA_ACTION },
      ).then((token: string) => {
        setRecaptchaToken(token);
      });
    }
  }, []);

  const handleRecaptchaLoaded = () => {
    // @ts-ignore
    window.grecaptcha.ready(() => {
      // @ts-ignore
      executeReCaptcha();
    });
  };

  useEffect(() => {
    if (recaptchaToken === null) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      recaptchaScript.addEventListener('load', handleRecaptchaLoaded);
      document.body.appendChild(recaptchaScript);
    }
  });

  const handleTermsChange = useCallback((newValue: boolean) => {
    setTermsAccepted(newValue);
  }, []);

  const handleFormChange = useCallback((key: keyof SignUpData, value: string) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (['email', 'password'].includes(key)) {
      setFormError((prev) => ({
        ...prev,
        [key]: validationFns[key](value),
      }));
    }
  }, []);

  const handleSignup = () => {
    const {
      email, password, firstName, lastName, role, confirmEmail,
    } = form;

    if (email !== confirmEmail) {
      setSignupError('Emails do not match.');
      return;
    }

    if (!role) {
      setSignupError('Please select a SHIPPER or CARRIER role.');
      return;
    }

    setLoading(true);
    signup({
      email,
      first_name: firstName,
      last_name: lastName,
      recatpcha_token: recaptchaToken,
    }, password, role).then(() => {
      setSignupError(null);
      navigate('/sign-up/success');
    }).catch(
      (e) => {
        if (e?.response?.status === 429) {
          setSignupError('Exceeded signup attempts. Please try again in 60 seconds.');
          return;
        } if (e?.response?.data?.code === 'RECAPTCHA_DUPE' || e?.response?.data?.code === 'RECAPTCHA_EXPIRED') {
          setSignupError(e?.response?.data?.message || 'There was an error while creating your account. Please contact us if the issue persists.');
        } else {
          setSignupError(e?.response?.data?.message || 'There was an error while creating your account. Please contact us if the issue persists.');
        }

        // re-execute recpatcha with a new token to prevent sending stale tokens
        executeReCaptcha();
      },
    ).finally(() => {
      setLoading(false);
    });
  };

  useInterval(executeReCaptcha, 60000);

  return (
    <SignUp
      onFormChange={handleFormChange}
      onSignup={handleSignup}
      role={form.role}
      formError={formError}
      showPassword={passwordIsVisible}
      onShowPasswordChange={handleShowPasswordChange}
      signupDisabled={form.email.length === 0
        || form.password.length === 0
        || form.firstName.length === 0
        || form.lastName.length === 0
        || (form.role && form.role.length === 0)
        || formError.email != null
        || formError.password != null
        || !termsAccepted}
      signupError={signupError}
      onTermsChange={handleTermsChange}
      loading={loading}
    />
  );
}
