import StraightenIcon from '@mui/icons-material/Straighten';
import { Stack, Typography } from '@mui/material';
import { ShipmentDetail } from 'components/Shipment/components/ShipmentDetail';
import { DateTime } from 'luxon';
import {
  meterToKilometer, meterToMile, removeDecimalPoint,
} from 'utils';
import { SuggestedNavigationRouteMap } from '../SuggestedNavigationRouteMap';

interface Props {
  route: Route
  origin: RoutePoint;
  destination: RoutePoint;
  midPoints: RoutePoint[];
}

export function SuggestedRoute(props: Props) {
  const {
    route,
    origin,
    destination,
    midPoints,
  } = props;

  const { navigation: suggestedRoute } = route;

  function routeCreatedAfterNavigationRollout() {
    if (route?.created_at) {
      const asDateTime = DateTime.fromISO(route.created_at);
      return new Date(2025, 2, 10) < asDateTime.toJSDate();
    }
    return true;
  }

  if (!suggestedRoute || suggestedRoute.payload == null) {
    return (
      <div>
        <Typography sx={{ mt: '1rem', mb: '1rem' }} textAlign="center" variant="body1">
          {routeCreatedAfterNavigationRollout() ? 'Suggested route not available yet, please check back later.' : 'Suggested route not available for this shipment.'}
        </Typography>
      </div>
    );
  }

  return (
    <Stack direction="column" width="100%">
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        <ShipmentDetail
          icon={<StraightenIcon />}
          title="Route Distance"
          text={`${removeDecimalPoint(meterToMile(suggestedRoute.distance_meters))} mi
           (${removeDecimalPoint(meterToKilometer(suggestedRoute.distance_meters))} kms)`}
        />
      </Stack>
      <SuggestedNavigationRouteMap
        navigation={suggestedRoute}
        origin={origin}
        destination={destination}
        midPoints={midPoints}
      />
      <Typography variant="body1" textAlign="center" sx={{ mt: '.5rem' }}>
        Route distance is approximate and may vary. Please verify as needed.
      </Typography>
    </Stack>
  );
}
