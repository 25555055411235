import { Box, Stack, Typography } from '@mui/material';

interface Props {
  icon: React.ReactNode;
  title: string;
  text: string | React.ReactNode;
  multiline?: boolean;
}

export function ShipmentDetail(props: Props) {
  const {
    icon, title, text, multiline,
  } = props;
  return (
    <Box mx="1rem" my="0.5rem">
      <Stack color="#a9a9a9" spacing={0.5} direction="row" alignItems="center">
        {icon}
        <Typography fontSize="small">
          {title}
        </Typography>
      </Stack>
      <Typography sx={multiline ? { whiteSpace: 'pre-line' } : undefined}>
        {text}
      </Typography>
    </Box>
  );
}

ShipmentDetail.defaultProps = {
  multiline: false,
};
