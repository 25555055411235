import { useShipmentRoute } from 'hooks';
import { useEffect, useRef } from 'react';
import { RouteTab } from './RouteTab';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function RouteTabContainer(props: Props) {
  const { shipment } = props;
  const shipmentRef = useRef<undefined | CondensedShipment>();
  const {
    route, error, loading, refetchRoute,
  } = useShipmentRoute(shipment?.info.route);

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the route is re-fetched when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      refetchRoute();
    }
  }, [shipment, refetchRoute]);

  if (!shipment) {
    return <div />;
  }

  return (
    <RouteTab
      error={error}
      route={route}
      loading={loading}
    />
  );
}
