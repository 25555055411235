import {
  Alert, Button, ButtonGroup, Snackbar, Stack,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDisclosure } from 'hooks';

interface Props {
  value: QuoteFilterType;
  onChange: (value: QuoteFilterType) => void;
  shipmentBooked: boolean;
  showCountered: boolean;
  showCancelled: boolean;
}

export function QuoteFilterMenu(props: Props) {
  const {
    value,
    onChange: OnMenuOptionSelected,
    shipmentBooked,
    showCountered,
    showCancelled,
  } = props;

  const { isOpen, onClose, onOpen } = useDisclosure(false);

  const onChange = (selection: QuoteFilterType) => {
    onOpen();
    OnMenuOptionSelected(selection);
  };

  return (
    <Stack p=".2rem" alignItems="center">
      <ButtonGroup>
        {shipmentBooked && (<Button onClick={() => onChange('ACCEPTED')} variant={value === 'ACCEPTED' ? 'contained' : 'outlined'}>ACCEPTED</Button>)}
        <Button onClick={() => onChange('ALL')} variant={value === 'ALL' ? 'contained' : 'outlined'}>All</Button>
        <Button onClick={() => onChange('PARTNER')} variant={value === 'PARTNER' ? 'contained' : 'outlined'}>Partner</Button>
        {shipmentBooked && (<Button onClick={() => onChange('LOST')} variant={value === 'LOST' ? 'contained' : 'outlined'}>LOST</Button>)}
        {!shipmentBooked && <Button onClick={() => onChange('ACTIVE')} variant={value === 'ACTIVE' ? 'contained' : 'outlined'}>ACTIVE</Button>}
        {!shipmentBooked && showCountered && <Button onClick={() => onChange('COUNTERED')} variant={value === 'COUNTERED' ? 'contained' : 'outlined'}>COUNTERED</Button>}
        {showCancelled && <Button onClick={() => onChange('CANCELLED')} variant={value === 'CANCELLED' ? 'contained' : 'outlined'}>CANCELED</Button>}
        <Button onClick={() => onChange('BY_TRUCKING_COMPANY')} variant={value === 'BY_TRUCKING_COMPANY' ? 'contained' : 'outlined'}>TRUCKING COMPANY</Button>
        <Button onClick={() => onChange('BY_BROKER')} variant={value === 'BY_BROKER' ? 'contained' : 'outlined'}>BROKER</Button>

      </ButtonGroup>
      <Snackbar
        id={value}
        open={isOpen}
        autoHideDuration={1000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          Filter Applied!
        </Alert>
      </Snackbar>
    </Stack>
  );
}
