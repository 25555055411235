import { useAPI } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { CreateShipmentTemplateModal } from '../CreateShipmentTemplateModal';
import { geCreateTemplateBodyFromCondensedShipment } from './utils';

interface Props {
  shipment: ShipperCondensedShipment;
  onTemplateCreated: VoidFunction;
  modalOpen: boolean;
  onModalClose: VoidFunction;
}

export function CreateTemplateFromShipmentModal(props: Props) {
  const {
    shipment, onTemplateCreated, modalOpen, onModalClose,
  } = props;

  const shipmentRef = useRef<undefined | ShipperCondensedShipment>();
  const [templateName, setTemplateName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { api } = useAPI();
  const [loadRoute, setLoadRoute] = useState<boolean>(true);
  const [route, setRoute] = useState<Route | null>(null);

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the route is re-fetched when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setLoadRoute(true);
    }
  }, [shipment]);

  useEffect(() => {
    if (loadRoute && shipment?.info?.route) {
      api<GetRouteResponse>('get', `/routes/${shipment.info.route}`).then((response) => {
        setRoute({
          id: response.data?.route?.id,
          type: response.data?.route?.type,
          route_points: response?.data.route?.route_points,
          navigation: null,
        });
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch route');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loadRoute, shipment, api]);

  const onSubmit = () => {
    const body = geCreateTemplateBodyFromCondensedShipment(shipment, templateName, route);
    setSuccess(false);
    setIsLoading(true);
    api('post', '/shipment-templates', body).then(() => {
      setSuccess(true);
      setError(null);
      onTemplateCreated();
      setTemplateName('');
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to shipment template. Please contact us.');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <CreateShipmentTemplateModal
      modalOpen={modalOpen}
      error={error}
      setTemplateName={(newValue: string) => { setTemplateName(newValue); }}
      templateName={templateName}
      onSubmit={onSubmit}
      loading={loading}
      success={success}
      onModalClose={onModalClose}
    />
  );
}
