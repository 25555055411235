import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { CompanyBusinessTypeChip } from 'components/CompanyBusinessTypeChip';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentFootageText } from 'components/Texts/ShipmentFootageText';
import { ShipmentIDText } from 'components/Texts/ShipmentIDText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentWeightText } from 'components/Texts/ShipmentWeightText';
import { useCarrierShipmentPageContext } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { toCityProvinceCountryAndZipReadablePointLocation } from 'utils';
import { EVOTRUX_BLUE, getServiceTypeDisplayLabel } from '../../../../constants';
import { MultiShipmentProjectLoadsAvailableLoadsLabel } from '../MultiShipmentProjectLoadsAvailableLoadsLabel';
import { PartnerShipmentChip } from '../PartnerShipmentChip';
import { QuotedShipmentChip } from '../QuotedShipmentChip';

interface Props {
  shipment: CarrierCondensedShipment;
  selected: boolean;
  select: (id: number) => void;
  selectionIsDisabled: boolean;
  displayShipperCompanyName?: boolean;
}

export function CompactShipmentPreview(props: Props) {
  const {
    shipment, selected, select, selectionIsDisabled, displayShipperCompanyName,
  } = props;
  const navigate = useNavigate();
  const { onRefresh } = useCarrierShipmentPageContext();

  const shipmentInfo = shipment.info;
  const equipmentTypes = shipment?.equipments;
  const bg = selected ? '#e3e3e3' : 'white';
  const getEquipmentTypesDisplay = () => {
    let equipmentTypesDisplay = '-';
    if (equipmentTypes.length > 1) {
      equipmentTypesDisplay = `${equipmentTypes[0]?.name}`;
      /*
        This will result in a string that looks like:
        "Dry Van (+ 2 more)"
        Indicating that the shipment has a dry van equipment specified, plus 2 additional types.
      */
      equipmentTypesDisplay = `${equipmentTypesDisplay} (+ ${equipmentTypes.length - 1} more)`;
    } else if (equipmentTypes.length === 1) {
      equipmentTypesDisplay = `${equipmentTypes[0]?.name}`;
    }
    return equipmentTypesDisplay;
  };

  return (
    <Box
      p="1rem"
      sx={{
        '&:hover': {
          bgcolor: selectionIsDisabled ? '#959696' : '#f8f8f8',
          cursor: selectionIsDisabled ? 'wait' : 'pointer',
        },
        background: bg,
      }}
      onClick={() => {
        if (!selectionIsDisabled) {
          if (onRefresh) {
            onRefresh();
          }
          navigate(`/shipments/${shipmentInfo.id}`);
          select(shipmentInfo.id);
        }
      }}
    >
      <Box mb="0.5rem">
        <ShipmentEquipmentTypeText data={[
          { name: getEquipmentTypesDisplay().toLowerCase().split('_').join(' ') } as Equipment]}
        />
        <MultiShipmentProjectLoadsAvailableLoadsLabel shipment={shipment} />
        <Typography sx={{ textTransform: 'capitalize' }}>{getServiceTypeDisplayLabel(shipmentInfo.service)}</Typography>
        {
          (displayShipperCompanyName || shipment.is_partner_shipment)
          && (<Typography color={EVOTRUX_BLUE}><b>{shipment?.shipment_company?.name || ''}</b></Typography>)
          }
      </Box>
      <Stack direction="row" spacing={2} width="100%" flexWrap="wrap">
        <ShipmentWeightText data={parseFloat(shipmentInfo.weight_lb.toString())} />
        { shipmentInfo.footage
        && <ShipmentFootageText data={parseFloat(shipmentInfo.footage.toString())} />}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="stretch" flexWrap="wrap">
        <ShipmentIDText shipment={shipment} />
        <PartnerShipmentChip partner={shipment?.is_partner_shipment ?? false} />
        <QuotedShipmentChip quoted={shipment?.has_carrier_active_quote ?? false} />
        <CompanyBusinessTypeChip businessType={shipment.shipment_company?.business_type} />
      </Stack>
      <Stack spacing={1} mt="1rem" flexWrap="wrap">
        <ShipmentLocationText
          data={toCityProvinceCountryAndZipReadablePointLocation(
            shipment.first_point.point_location,
          )}
          icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        />
        <ShipmentLocationText
          data={toCityProvinceCountryAndZipReadablePointLocation(
            shipment.last_point.point_location,
          )}
          icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        />
      </Stack>
    </Box>
  );
}

CompactShipmentPreview.defaultProps = {
  displayShipperCompanyName: false,
};
