import {
  Box, Button, Checkbox, CircularProgress, Modal, Stack, Typography,
} from '@mui/material';
import { useAPI } from 'hooks';
import { MODAL_STYLE } from 'layouts/constants';
import { useState } from 'react';
import { ISOStringToReadableDate } from 'utils';
import { EVOTRUX_BLUE } from '../../../../constants';

interface Props {
  shipment: CondensedShipment | undefined;
  visible: boolean;
  onClose: VoidFunction;
  onBook: VoidFunction;
}

export function BookNowConfirmationModal(props: Props) {
  const {
    shipment, visible, onClose, onBook,
  } = props;
  const [canMeetReqs, setCanMeetReqs] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { api } = useAPI();
  const [error, setError] = useState<string | null>(null);

  if (shipment === undefined) {
    return <div />;
  }

  const submitBookNow = () => {
    setLoading(true);
    setError(null);
    onBook();
    api<ActiveJoinCompanyRequest[]>('post', `/shipments/${shipment.info.id}/book-now`).then(() => {
      setError(null);
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to book shipment now. Please contact us if the issue persists.');
    }).finally(() => {
      setLoading(false);
    });
  };

  const getEquipmentTypesDisplay = (equipmentTypes: Equipment[]) => {
    let equipmentTypesDisplay = '';
    equipmentTypes?.forEach((equipmentType, index) => {
      if (index === 0) {
        equipmentTypesDisplay = `${equipmentType.name}`;
      } else {
        equipmentTypesDisplay = `${equipmentTypesDisplay}, ${equipmentType.name}`;
      }
    });
    return equipmentTypesDisplay;
  };

  const onModalClose = () => {
    setCanMeetReqs(false);
    onClose();
  };

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={MODAL_STYLE}>
        <Stack direction="column">
          <Typography variant="h5">
            <b>{`Book this load now for $${shipment.book_now_info.book_now_price} ${shipment.book_now_info.book_now_currency}`}</b>
          </Typography>
          <Stack
            sx={{
              borderColor: EVOTRUX_BLUE, borderRadius: 2, p: '1rem', mt: '1rem', mb: '1rem',
            }}
            boxShadow={1}
            direction="column"
          >
            <Typography>
              <b>Service:</b>
              {' '}
              {shipment.info.service}
            </Typography>
            <Typography>
              <b>Weight:</b>
              {' '}
              {`${shipment.info.weight_lb}lb`}
            </Typography>
            <Typography>
              <b>Pick up:</b>
              {' '}
              {ISOStringToReadableDate(shipment.first_point.start_time)}
              {' - '}
              {ISOStringToReadableDate(shipment.first_point.end_time)}
            </Typography>
            <Typography>
              <b>Deliver:</b>
              {' '}
              {ISOStringToReadableDate(shipment.last_point.start_time)}
              {' - '}
              {ISOStringToReadableDate(shipment.last_point.end_time)}
            </Typography>
            <Typography>
              <b>Equipment:</b>
              {' '}
              {getEquipmentTypesDisplay(shipment.equipments)}
            </Typography>
            <Typography>
              <b>Special Requirements:</b>
              {' '}
              {shipment.info?.special_requirements ?? ''}
            </Typography>
            <Typography>
              <b>Product Dimensions:</b>
              {' '}
              {shipment.info?.additional_details ?? ''}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" mt="1rem" mb="1rem">
            <Checkbox
              required
              checked={canMeetReqs}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => setCanMeetReqs(event.target.checked)
  }
            />
            <Typography variant="h6" color="black">I confirm I will pick up this shipment with the requested equipments and  can meet all service requirements listed above.</Typography>

          </Stack>
          {error !== null && <Typography color="red" align="center">{error}</Typography>}
          {!loading && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="success"
              disabled={!canMeetReqs}
              onClick={submitBookNow}
            >

              {`BOOK NOW $${shipment.book_now_info.book_now_price} ${shipment.book_now_info.book_now_currency}`}

            </Button>
            <Button variant="outlined" onClick={onModalClose}>cancel</Button>
          </Stack>
          )}
          {
            loading && (
              <Stack>
                <CircularProgress />
              </Stack>
            )
          }
        </Stack>
      </Box>
    </Modal>
  );
}
