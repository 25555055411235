import { Button, Stack, Typography } from '@mui/material';
import { CarrierUpgradeNowButton } from 'components/Carrier/components/CarrierUpgradeNowButton';
import { JumpToChatButton } from 'components/JumpToChatButton';
import { BlockCarrierButton } from 'components/Shipper/components/BlockCarrierButton';
import { useRoleContext } from 'hooks';
import { QuoteActionModal } from './components/QuoteActionModal';
import { QuoteActionState } from './types';

interface Props {
  viewQuoteHistory: React.ReactNode;
  acceptQuote: QuoteActionState;
  counterQuote: QuoteActionState;
  cancelQuote: QuoteActionState;
  declineQuoteWithFeedback: QuoteActionState;
  declineQuote: QuoteActionState;
  modalOpen: boolean;
  onCloseModal: VoidFunction;
  onActionClicked: (actionType: CondensedQuoteActionValue) => void;
  modalAction: CondensedQuoteActionValue | null;
  quote: CondensedQuote;

}

export function QuoteInteraction(props: Props) {
  const {
    viewQuoteHistory,
    acceptQuote,
    counterQuote,
    cancelQuote,
    modalOpen,
    onCloseModal,
    onActionClicked,
    modalAction,
    quote,
    declineQuoteWithFeedback,
    declineQuote,
  } = props;
  const { role } = useRoleContext();

  const getAcceptQuoteButtonLabel = () => {
    if (role && role.type === 'CARRIER') {
      return 'Book Load';
    }

    if (role && role.type === 'SHIPPER' && quote?.quote_type === 'MULTI_SHIPMENT_PROJECT') {
      return 'Assign Shipments';
    }

    return 'Book';
  };

  const showBlockCarrierButton = role && role.type === 'SHIPPER';

  return (
    <>
      {modalOpen
        ? (
          <QuoteActionModal
            action={modalAction}
            onClose={onCloseModal}
            quote={quote}
          />
        )
        : null}
      <Stack direction="row">
        <Stack
          id="actions-stack"
          width="80%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {
          (quote.internal_state === 'ACCEPTED') && (
            <JumpToChatButton shipmentId={quote.shipment} />
          )
        }
          {(acceptQuote.isDisplayed) && (
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => onActionClicked(acceptQuote.actionType)}
          >
            <Typography fontSize="0.8rem" fontWeight="bold">{getAcceptQuoteButtonLabel()}</Typography>
          </Button>
          )}
          {(counterQuote.isDisplayed) && (
          <Button
            color="primary"
            fullWidth
            variant="outlined"
            onClick={() => onActionClicked(counterQuote.actionType)}
          >
            <Typography fontSize="0.8rem" fontWeight="bold">Negotiate</Typography>
          </Button>
          )}
          {(cancelQuote.isDisplayed) && (
          <Button
            color="error"
            fullWidth
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={() => onActionClicked(cancelQuote.actionType)}
          >
            <Typography fontSize="0.8rem" fontWeight="bold">Cancel</Typography>
          </Button>
          )}
          {(declineQuoteWithFeedback.isDisplayed) && (
          <Button
            color="warning"
            fullWidth
            variant="outlined"
            onClick={() => onActionClicked(declineQuoteWithFeedback.actionType)}
          >
            <Typography fontSize="0.8rem" fontWeight="bold">Decline</Typography>
          </Button>
          )}
          {(declineQuote.isDisplayed) && (
            <Button
              color="warning"
              fullWidth
              variant="outlined"
              onClick={() => onActionClicked(declineQuote.actionType)}
            >
              <Typography fontSize="0.8rem" fontWeight="bold">Decline</Typography>
            </Button>
          )}
          {
            (quote.hidden_at != null) && (
            <CarrierUpgradeNowButton size="small" text="View Plans" />
            )
          }
          {
            (quote.hidden_at != null) && (
              <Stack>
                <Typography color="red" variant="body1">
                  You have no bookings available.
                  The shipper will not receive your quote or have
                  the ability to book it until you upgrade your account.
                </Typography>
              </Stack>
            )
          }
        </Stack>
        <Stack
          width="50%"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {viewQuoteHistory}
          {showBlockCarrierButton && (<Typography variant="body1">|</Typography>)}
          {showBlockCarrierButton && (
          <BlockCarrierButton
            carrierId={quote.carrier_id}
            firstName={quote.created_by.first_name}
            lastName={quote.created_by.last_name}
            companyName={quote.created_by_company.name}
          />
          )}
        </Stack>
      </Stack>
    </>
  );
}
