import {
  Box, CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { CarrierInsuranceDocs } from 'components/CarrierInsuranceDocs';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useCompanyProfile } from 'hooks';
import { ISOStringToShorthandDate } from 'utils';
import { CompanyReviewMetaLabel } from '../CompanyReviewMetaLabel';

interface Props {
  companyId: number;
}

export function CompanyProfileHeader(props: Props) {
  const { companyId } = props;
  const { companyProfile, loading, error } = useCompanyProfile(companyId);

  if (loading || companyProfile == null) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  const isCarrierCompany = companyProfile.company.company_type === 'CARRIER';

  return (
    <Stack direction="column">
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h3">{companyProfile?.company.name}</Typography>
        <Typography variant="body1" color="text.secondary">
          {`${companyProfile?.address.city}, ${companyProfile?.address.country ?? ''}`}
        </Typography>
        <EvotruxDivider />
      </Box>
      <Stack direction="row" spacing={2}>
        <Typography variant="h4" color="text.secondary">Company Rating:</Typography>
        {companyProfile && companyProfile?.reviews_meta && (<CompanyReviewMetaLabel reviewsMeta={companyProfile.reviews_meta} labelType="h4" starIconSize="large" />)}
      </Stack>
      <Typography variant="h5" color="text.secondary" gutterBottom>
        Member since
        {' '}
        {companyProfile?.company?.created_at
          && ISOStringToShorthandDate(companyProfile.company.created_at)}
      </Typography>

      {
        isCarrierCompany && (
          <Typography variant="h6">
            <b>{'Insurance documents & more: '}</b>
          </Typography>
        )
      }
      {
        isCarrierCompany && (
          <CarrierInsuranceDocs companyProfile={companyProfile} />
        )
      }
    </Stack>
  );
}
