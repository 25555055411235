import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalActivity from '@mui/icons-material/LocalActivity';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { ShipmentStatusBadge } from 'components/ShipmentStatusBadge';
import { ShipmentCustomNameText } from 'components/Texts/ShipmentCustomNameText';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentFootageText } from 'components/Texts/ShipmentFootageText';
import { ShipmentIDText } from 'components/Texts/ShipmentIDText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentWeightText } from 'components/Texts/ShipmentWeightText';
import { useNavigate } from 'react-router-dom';
import { quoteCountText, toCityProvinceCountryAndZipReadablePointLocation } from 'utils';
import { getServiceTypeDisplayLabel } from '../../../../constants';
import { MultiShipmentProjectLoadsAvailableLoadsLabel } from '../MultiShipmentProjectLoadsAvailableLoadsLabel';
import { SharedShipmentChip } from '../SharedShipmentChip';

interface Props {
  shipment: ShipperCondensedShipment;
  selected: boolean;
  select: (id: number) => void;
  selectionIsDisabled: boolean;
}

export function CompactShipmentPreview(props: Props) {
  const {
    shipment, selected, select, selectionIsDisabled,
  } = props;
  const navigate = useNavigate();
  const shipmentInfo = shipment.info;
  const equipmentTypes = shipment?.equipments;
  const bg = selected ? '#e3e3e3' : 'white';
  const getEquipmentTypesDisplay = () => {
    let equipmentTypesDisplay = '-';
    if (equipmentTypes.length > 1) {
      equipmentTypesDisplay = `${equipmentTypes[0]?.name}`;
      /*
        This will result in a string that looks like:
        "Dry Van (+ 2 more)"
        Indicating that the shipment has a dry van equipment specified, plus 2 additional types.
      */
      equipmentTypesDisplay = `${equipmentTypesDisplay} (+ ${equipmentTypes.length - 1} more)`;
    } else if (equipmentTypes.length === 1) {
      equipmentTypesDisplay = `${equipmentTypes[0]?.name}`;
    }
    return equipmentTypesDisplay;
  };

  return (
    <Box
      p="1rem"
      sx={{
        '&:hover': {
          bgcolor: selectionIsDisabled ? '#959696' : '#f8f8f8',
          cursor: selectionIsDisabled ? 'wait' : 'pointer',
        },
        background: bg,
      }}
      onClick={() => {
        if (!selectionIsDisabled) {
          navigate(`/shipments/${shipmentInfo.id}`);
          select(shipmentInfo.id);
        }
      }}
    >
      <Box mb="0.5rem">
        <ShipmentEquipmentTypeText data={[
          { name: getEquipmentTypesDisplay().toLowerCase().split('_').join(' ') } as Equipment]}
        />
        <MultiShipmentProjectLoadsAvailableLoadsLabel shipment={shipment} />
        <Typography sx={{ textTransform: 'capitalize' }}>{getServiceTypeDisplayLabel(shipmentInfo.service)}</Typography>
        {shipmentInfo.type === 'MULTI_SHIPMENT_PROJECT'
        && (<Typography variant="body1">Multi Shipment Project</Typography>)}
      </Box>
      <Stack direction="row" spacing={2} width="100%" flexWrap="wrap">
        <ShipmentWeightText data={parseFloat(shipmentInfo.weight_lb.toString())} />
        { shipmentInfo.footage
        && <ShipmentFootageText data={parseFloat(shipmentInfo.footage.toString())} />}
      </Stack>
      <ShipmentIDText shipment={shipment} />
      {shipment && shipment.custom_name != null
       && (
       <ShipmentCustomNameText data={shipment.custom_name} />
       ) }
      <ShipmentStatusBadge status={shipment?.shipment_status} size="small" />
      <SharedShipmentChip shipment={shipment} size="small" />
      <Stack spacing={1} mt="1rem">
        <ShipmentLocationText
          data={toCityProvinceCountryAndZipReadablePointLocation(
            shipment.first_point.point_location,
          )}
          icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        />
        <ShipmentLocationText
          data={toCityProvinceCountryAndZipReadablePointLocation(
            shipment?.last_point.point_location,
          )}
          icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        />
        <ShipmentLocationText
          data={quoteCountText(shipment?.condensed_quotes.filter(
            (q) => q.hidden_at == null && !q.carrier_is_blocked,
          ).length)}
          icon={<LocalActivity sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        />
      </Stack>
    </Box>
  );
}
