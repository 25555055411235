import { carrierShipmentPageContext } from 'context';
import { useContext } from 'react';
/*
Exposes root information about the shipment detail page
to all compontents down the tree. Use this hook in components
that only CARRIER role users will see.
*/
export function useCarrierShipmentPageContext() {
  const carrierShipmentPageCtx = useContext(carrierShipmentPageContext);

  return {
    onRefresh: carrierShipmentPageCtx.onRefresh,
    shipment: carrierShipmentPageCtx.shipment,
    shipmentFetchError: carrierShipmentPageCtx.error,
  };
}
