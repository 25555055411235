import FlagIcon from '@mui/icons-material/Flag';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import {
  useDisclosure, useRoleContext, useShipperShipmentPageContext, useWriterComponentState,
} from 'hooks';
import { MODAL_STYLE } from 'layouts/constants';

interface Props {
  carrierId: number;
  firstName?: string;
  lastName?: string;
  companyName: string;
}

export function BlockCarrierButton(props: Props) {
  const {
    carrierId, firstName, lastName, companyName,
  } = props;
  const { role } = useRoleContext();
  const { onRefresh } = useShipperShipmentPageContext();
  const {
    isOpen: confirmationModalOpen,
    onClose: onCloseConfirmationModal,
    onOpen: onOpenConfirmationModal,
  } = useDisclosure(false);
  const {
    write: blockCarrier, error, loading,
  } = useWriterComponentState({
    errorMessage: 'Failed to block carrier. Please contact us if the problem persists.',
    writeConfig: {
      method: 'post',
      url: '/user/block-carrier',
      body: {
        carrier_id: carrierId,
      },
    },
  });

  const showButton = role && role.type === 'SHIPPER';

  if (!showButton) {
    return null;
  }

  const onCarrierBlocked = () => {
    onCloseConfirmationModal();
    if (onRefresh) {
      onRefresh();
    }
  };

  const carrierName = `${firstName} ${lastName}`;

  return (
    <Box>
      <Tooltip title="Block Carrier" placement="top" TransitionComponent={Zoom}>
        <IconButton color="inherit" onClick={onOpenConfirmationModal}>
          <FlagIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal open={confirmationModalOpen} onClose={onCloseConfirmationModal}>
        <Box sx={MODAL_STYLE}>
          <Typography variant="h6" component="h2" textAlign="center">Block Carrier</Typography>
          <Divider sx={{ my: '1rem' }} />
          <Typography>
            {'Blocking a carrier will prevent them from accessing and quoting your loads. Are you sure you want to block '}
            <Typography component="span" fontWeight="bold">
              {carrierName}
            </Typography>
            {' from '}
            <Typography component="span" fontWeight="bold">
              {companyName}
            </Typography>
            ?
          </Typography>
          <ErrorTypography error={error} />
          <LoadingWrappedNode loading={loading}>

            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: '2rem' }}>
              <>
                <Button
                  variant="contained"
                  onClick={onCloseConfirmationModal}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => blockCarrier(onCarrierBlocked)}
                  variant="outlined"
                  color="error"
                >
                  Block Carrier
                </Button>
              </>
            </Stack>
          </LoadingWrappedNode>
        </Box>
      </Modal>

    </Box>

  );
}

BlockCarrierButton.defaultProps = {
  firstName: undefined,
  lastName: undefined,
};
