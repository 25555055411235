import {
  Typography,
} from '@mui/material';
import { useCarrierShipmentPageContext, useRoleContext } from 'hooks';
import { toCityProvinceCountryAndZipReadablePointLocation, toFullReadableAddress } from 'utils';

interface Props {
  routePoint: RoutePoint;
}

export function CarrierRoutePointLocationDisplay(props: Props) {
  const { routePoint } = props;
  const { role } = useRoleContext();
  const { shipment } = useCarrierShipmentPageContext();

  if (!shipment || (role && role.type !== 'CARRIER')) {
    return <div />;
  }

  // privileged address display
  if (shipment.booked_by_carrier) {
    return (
      <Typography variant="h6">{toFullReadableAddress(routePoint.point_location)}</Typography>

    );
  }

  // un-privileged view
  return (
    <Typography variant="h6">{toCityProvinceCountryAndZipReadablePointLocation(routePoint.point_location)}</Typography>
  );
}
