import { Stack, Typography } from '@mui/material';
import { CompanyBusinessTypeChip } from 'components/CompanyBusinessTypeChip';
import { CompanyReviewMetaLabel } from 'components/CompanyProfilePage/components/CompanyReviewMetaLabel';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { NewTabLink } from 'components/NewTabLink';
import { useCompanyProfile } from 'hooks';
import { getCompanyProfilePath } from 'utils';

interface Props {
  companyId: number;
  companyBusinessType?: BusinessTypeValue | null;
}

export function CarrierCompanyNameAndReputationLabel(props: Props) {
  const { companyId, companyBusinessType } = props;
  const { companyProfile, loading } = useCompanyProfile(companyId);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <NewTabLink href={getCompanyProfilePath(companyId)}>
        <Typography>View Carrier Profile</Typography>
      </NewTabLink>
      <Stack
        id="documents-and-more"
        direction="row"
        spacing={1}
        flexWrap="wrap"
      >
        <LoadingWrappedNode loading={loading}>
          {companyProfile && (
          <CompanyReviewMetaLabel
            reviewsMeta={companyProfile.reviews_meta}
            labelType="body1"
            starIconSize="small"
            showShorteneNoReviews
            showNoReviewsText={false}
          />
          )}
        </LoadingWrappedNode>
        {companyBusinessType && (
        <CompanyBusinessTypeChip
          businessType={companyBusinessType}
        />
        )}
      </Stack>

    </Stack>
  );
}

CarrierCompanyNameAndReputationLabel.defaultProps = {
  companyBusinessType: undefined,
};
