import { Feature, LineString } from 'geojson';

import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useState } from 'react';
import Map, {
  Layer,
  Marker,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl/mapbox';
/* eslint-disable import/no-webpack-loader-syntax */
import { Box } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { EVOTRUX_BLUE } from '../../../../../../constants';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// eslint-disable-next-line import/no-webpack-loader-syntax

const TOKEN = process.env.REACT_APP_MAPBOX_PUBLIC_KEY || 'ERROR';

const DEFAULT_ZOOM = 4;

interface Props {
  navigation: SuggestedRouteNavigation;
  origin: RoutePoint;
  destination: RoutePoint;
  midPoints: RoutePoint[];

}

interface MapState {
  zoom: number;
  latitude: number;
  longitude: number;
}

export function SuggestedNavigationRouteMap(props: Props) {
  const {
    navigation,
    origin,
    destination,
    midPoints,
  } = props;

  const [mapState, setMapState] = useState<MapState>({
    latitude: origin.point_location.latitude,
    longitude: origin.point_location.longitude,
    // the defualt value will need to be a coordinate that shows the whole
    // map, which also affects the zoom label
    zoom: DEFAULT_ZOOM,
  });

  const fitMapToBounds = useCallback((map: mapboxgl.Map) => {
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([origin.point_location.longitude, origin.point_location.latitude]);
    bounds.extend([destination.point_location.longitude, destination.point_location.latitude]);

    map.fitBounds(bounds, {
      padding: 100, // Padding around the markers
      duration: 1000, // Animation duration
    });
  }, [origin, destination]);

  const handleMapLoad = useCallback((event) => {
    const map = event.target;
    map.setLayoutProperty('poi-label', 'visibility', 'none');
    fitMapToBounds(map);
  }, [fitMapToBounds]);

  const validGeoJSON : Feature<LineString> = {
    type: 'Feature',
    properties: {},
    geometry: navigation.payload.routes[0].geometry,
  };

  return (
    <Box id="map-conatiner" sx={{ width: '100%', height: '50vh' }}>
      <Map
        reuseMaps
        mapboxAccessToken={TOKEN}
        longitude={mapState.longitude}
        latitude={mapState.latitude}
        zoom={mapState.zoom}
        style={{ width: '100%', height: '100%' }}
        onMove={(evt: ViewStateChangeEvent) => setMapState((prev) => ({
          ...prev,
          latitude: evt.viewState.latitude,
          longitude: evt.viewState.longitude,
          zoom: evt.viewState.zoom,
        }))}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
      >
        <Marker
          key="origin"
          longitude={origin.point_location.longitude}
          latitude={origin.point_location.latitude}
        />
        <Marker
          key="destination"
          longitude={destination.point_location.longitude}
          latitude={destination.point_location.latitude}
        />
        {
        midPoints.map((rp) => (
          <Marker
            key={rp.point_number}
            longitude={rp.point_location.longitude}
            latitude={rp.point_location.latitude}
          />
        ))
      }
        {navigation.payload.routes.length > 0 && (
        // @ts-ignore
        <Source
          id="route-source"
          type="geojson"
          data={validGeoJSON}
        >
          <Layer
            id="route-layer"
            type="line"
            paint={{
              'line-color': EVOTRUX_BLUE,
              'line-width': 4,
            }}
          />
        </Source>
        )}
      </Map>
    </Box>
  );
}
