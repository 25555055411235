import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider, FormControl, FormControlLabel, Stack, TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { NewTabLink } from 'components/NewTabLink';
import { useNavigate } from 'react-router-dom';
import { TERMS_OF_SERVICE_URL } from '../../../../constants';
import { EmailAndPasswordFormErrorState } from '../../types';
import { PasswordToggableIconEndAdornment } from '../PasswordToggableIconEndAdornment';
import { SignUpData } from './types';

interface Props {
  role: RoleValue | undefined;
  showPassword: boolean;
  onFormChange: (key: keyof SignUpData, value: string) => void;
  onShowPasswordChange: () => void;
  onSignup: VoidFunction;
  onTermsChange: (newValue: boolean) => void;
  formError: EmailAndPasswordFormErrorState;
  signupDisabled: boolean;
  signupError: string | null;
  loading: boolean;
}

export function SignUp(props: Props) {
  const {
    onFormChange,
    onShowPasswordChange,
    onSignup,
    onTermsChange,
    role,
    showPassword,
    formError,
    signupDisabled,
    signupError,
    loading,
  } = props;
  const navigate = useNavigate();
  const isMobileXS = useMediaQuery('(max-width:420px)');

  return (
    <Box>
      <Typography textAlign="center" variant="h4" fontWeight="bold">Signup for Evotrux</Typography>
      <Stack my="2rem" spacing={2}>
        <TextField
          id="email-input"
          label="Email"
          variant="outlined"
          onChange={(e) => onFormChange('email', e.target.value)}
          error={formError.email != null}
          helperText={formError.email ?? ''}
        />
        <TextField
          id="confirm-email-input"
          label="Confirm Email"
          variant="outlined"
          onChange={(e) => onFormChange('confirmEmail', e.target.value)}
        />
        <TextField
          id="password-input"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          onChange={(e) => onFormChange('password', e.target.value)}
          error={formError.password != null}
          helperText={formError.password ?? ''}
          InputProps={{
            endAdornment: (<PasswordToggableIconEndAdornment
              showPassword={showPassword}
              onIconClick={onShowPasswordChange}
            />
            ),
          }}
        />
        <TextField
          id="first-name-input"
          label="First name"
          variant="outlined"
          onChange={(e) => onFormChange('firstName', e.target.value)}
        />
        <TextField
          id="last-name-input"
          label="Family name"
          variant="outlined"
          onChange={(e) => onFormChange('lastName', e.target.value)}
        />
        <Stack direction={isMobileXS ? 'column' : 'row'} alignItems="center" spacing={2}>
          <Typography alignSelf="center">I am a</Typography>
          <ButtonGroup>
            <Button
              variant={role === 'SHIPPER' ? 'contained' : 'outlined'}
              onClick={() => onFormChange('role', 'SHIPPER')}
            >
              Shipper
            </Button>
            <Button
              variant={role === 'CARRIER' ? 'contained' : 'outlined'}
              onClick={() => onFormChange('role', 'CARRIER')}
            >
              Carrier
            </Button>
          </ButtonGroup>
        </Stack>
        <FormControl required>
          <FormControlLabel
            control={(
              <Checkbox
                onChange={(e) => onTermsChange(e.target.checked)}
              />
          )}
            label={(
              <>
                <span>I agree to the </span>
                <NewTabLink href={TERMS_OF_SERVICE_URL}>
                  <p>{' terms of service.'}</p>
                </NewTabLink>
              </>
)}
          />
        </FormControl>
        {signupError != null && <Typography color="red" align="center">{signupError}</Typography>}
        <LoadingWrappedNode loading={loading}>
          <Stack direction="column">
            <Button disabled={signupDisabled} variant="contained" onClick={onSignup}>
              SignUp
            </Button>
          </Stack>

        </LoadingWrappedNode>

        <Divider variant="middle" />
        <Button onClick={() => navigate('/login')}>Have an account?</Button>
      </Stack>
    </Box>
  );
}
