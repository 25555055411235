import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { BlockCarrierButton } from 'components/Shipper/components/BlockCarrierButton';
import { useDisclosure, useRoleContext } from 'hooks';
import { getCompanyProfilePath, ISOStringToLocalReadableTimestamp } from '../../../../../utils';
import { SubmitAnswerMenu } from './SubmitAnswerMenu/SubmitAnswerMenu';

interface Props {
  shipmentQA: ShipmentQA
}

export function ShipmentQA(props: Props) {
  const { shipmentQA } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { role } = useRoleContext();

  const getReplyButton = () => {
    // Only render reply button if user is a shipper AND question has not been answered already
    if (role && role.type === 'SHIPPER' && shipmentQA.answer == null) {
      return (
        <div>
          { isOpen ? <Button onClick={onClose}>Cancel</Button>
            : <Button variant="contained" onClick={onOpen} startIcon={<ReplyIcon />}>Reply</Button> }
        </div>
      );
    }
    return <div />;
  };
  const getAuthor = (
    firstName?: string,
    companyName?: string,
    timestamp?: string,
    companyId?: number,
  ) => (
    <Stack direction="row" mt="1rem" mb="1rem" spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <PersonIcon />
        <Typography variant="body1">{firstName ?? '-'}</Typography>
      </Stack>
      {companyId !== undefined && companyName !== undefined && (
      <Stack direction="row" spacing={1} alignItems="center">
        <ApartmentIcon />
        <NewTabLink href={getCompanyProfilePath(companyId)}>
          <Typography variant="body1">{companyName}</Typography>
        </NewTabLink>

      </Stack>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <AccessTimeIcon />
        <Typography variant="body1">{timestamp ? ISOStringToLocalReadableTimestamp(timestamp) : '-'}</Typography>
      </Stack>
      {
        role && role.type === 'SHIPPER' && (
        <BlockCarrierButton
          carrierId={shipmentQA.question.role.id}
          firstName={shipmentQA.question.role.user.first_name}
          lastName={shipmentQA.question.role.user.last_name}
          companyName={shipmentQA.question.role.company.name}
        />
        )
      }
    </Stack>
  );

  const getShipperAuthor = (firstName?: string, timestamp?: string) => (
    <Stack direction="row" mt="1rem" mb="1rem" spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <PersonIcon />
        <Typography variant="body1">{firstName ?? '-'}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <AccessTimeIcon />
        <Typography variant="body1">{timestamp ? ISOStringToLocalReadableTimestamp(timestamp) : '-'}</Typography>
      </Stack>
    </Stack>
  );

  const getAnswer = () => (shipmentQA.answer != null ? (
    <Stack direction="row" alignContent="flex-start" ml=".5rem" alignItems="flex-end" mb="1rem">
      <Box sx={{ border: '1px solid #8d8d8dad', height: '5rem', mr: '.5rem' }} />
      <Stack direction="column">
        {getShipperAuthor(
          shipmentQA?.answer?.role?.user?.first_name,
          shipmentQA?.answer?.created_at,
        )}
        <Typography
          variant="h5"
          sx={{
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        >
          {shipmentQA?.answer?.text}
        </Typography>
      </Stack>
    </Stack>
  ) : null);

  return (
    <Stack direction="column">
      {getAuthor(
        shipmentQA?.question?.role?.user?.first_name,
        shipmentQA?.question?.role?.company?.name,
        shipmentQA?.question?.created_at,
        shipmentQA?.question?.role.company.id,
      )}
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography
          variant="h5"
          sx={{
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        >
          {shipmentQA?.question?.text ?? '-'}
        </Typography>
        <>
          {getReplyButton()}
        </>
      </Stack>
      {getAnswer()}
      {isOpen && <SubmitAnswerMenu questionId={shipmentQA.question.id} /> }
      <Divider sx={{ mt: '1rem' }} />
    </Stack>
  );
}
